<script lang="ts">
  import { createEventDispatcher } from 'svelte'
  import ActiveLink from '../ActiveLink.svelte'
  export let activeWhen: RegExp = null
  export let featureEnabled = false
  export let url = '#'
  export let title = ''
  export let iconSrc = ''
  export let external: true | null = null

  const dispatch = createEventDispatcher()
  function handleClick() {
    dispatch('close')
  }
</script>

<ActiveLink
  on:click={handleClick}
  class={`text-black group flex items-center px-6 py-3 text-base rounded-full 2xl:text-lg ${
    featureEnabled ? ' hover:bg-grey-md' : 'pointer-events-none opacity-50'
  }`}
  href={url}
  {external}
  {activeWhen}
  disabled={!featureEnabled}
>
  <img src={iconSrc} alt="nav item icon" height={20} width={20} />
  <p class="ml-5">{title}</p>
  {#if !featureEnabled}
    <span
      class="inline-flex items-center px-2 py-0.5 ml-4 rounded-full text-xs font-medium bg-grey-main text-gray-800"
    >
      Soon
    </span>
  {/if}
</ActiveLink>
