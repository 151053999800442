<script lang="ts">
  import { ArrowRight } from '@steeze-ui/heroicons'
  import { Icon } from '@steeze-ui/svelte-icon'
  import type { Banner } from 'src/utils/banner'
  export let banner: Banner | null = null
</script>

{#if banner}
  <div class="relative bg-black z-30 w-full">
    <div class="max-w-screen-2xl mx-auto py-3 px-3 lg:px-8 sm:px-4 font-medium text-white">
      <a
        href={banner.url}
        data-sveltekit-preload-data
        class="text-white flex flex-col md:flex-row md:items-center md:justify-between"
      >
        <span class="md:hidden">{banner.headline}</span>
        <span class="hidden md:block">{banner.headline}</span>
        <span class="block sm:inline-block mr-2">
          {#if banner.cta}
            <button
              aria-label="Learn more"
              class="flex items-center text-white sm:mt-0 focus:outline-none focus:ring-none"
            >
              <span class="mr-2 text-sm font-medium">{banner.cta}</span>
              <div
                class={`rounded-full h-4 w-4 flex items-center justify-center transform transition duration-100 ease-in-out translate-x-0 group-hover:translate-x-2`}
              >
                <Icon src={ArrowRight} class="w-4 h-4" />
              </div>
            </button>
          {/if}
        </span>
      </a>
    </div>
  </div>
{/if}
