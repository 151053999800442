<script lang="ts">
  import { page } from '$app/stores'
  import { override } from '$lib/classnames'
  export let href = '#'
  export let activeWhen: RegExp = null
  export let active = false
  export let external: boolean | null = null
  export let disabled = false
  export let navIsOpen = false

  let className = ''
  let activeClassName = ''
  let inactiveClassName = ''

  export { className as class, activeClassName as activeClass, inactiveClassName as inactiveClass }
  $: internalActive =
    active ||
    ((activeWhen ? activeWhen.test($page.url.pathname) : $page.url.pathname == href) && !navIsOpen)
</script>

<a
  {href}
  on:mouseenter|self
  on:click
  on:mouseleave|self
  {disabled}
  data-sveltekit-reload={external}
  class={internalActive
    ? override(className, activeClassName)
    : override(className, inactiveClassName)}
  {...$$restProps}
>
  <slot active={internalActive} />
</a>
