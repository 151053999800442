<script lang="ts">
  import { page } from '$app/stores'
  import feature from '$lib/stores/feature'
  import { createEventDispatcher } from 'svelte'
  import { fade } from 'svelte/transition'

  $: bannerFeatureEnabled = $feature['banner']
  $: isBannerBlocked = $page.data.blockBanner

  export let isOpen = false
  const dispatch = createEventDispatcher()
  function closeMenu(event: MouseEvent) {
    event.preventDefault()
    dispatch('close')
  }
</script>

{#if isOpen}
  <div class="relative z-30">
    <div transition:fade|local={{ duration: 100 }} class="absolute inset-0" on:click={closeMenu}>
      <div
        class="fixed inset-0 bg-black overscroll-contain transform-gpu bg-opacity-20 backdrop-blur-sm backdrop-filter"
      />
    </div>

    <div
      class={`${
        bannerFeatureEnabled && !isBannerBlocked ? 'top-16 md:top-12' : 'top-0'
      } absolute inset-x-0 order-2 opacity-100 z-45 transition duration-200 ease-out scale-100 transform origin-top-right`}
    >
      <div class="pt-4 pb-6 bg-white rounded-b-lg shadow-lg ring-1 ring-black ring-opacity-5">
        <div class="flex items-center justify-between px-5">
          <a href="/" class="flex focus:outline-none focus:ring-none">
            <div class="flex items-center h-12 py-2">
              <img
                src="/airheart-logo-full-new.svg"
                class="h-8"
                height={32}
                width={128}
                alt="Airheart Logo"
              />
              <span class="sr-only">Go to the airheart home page</span>
            </div>
          </a>
          <div class="-mr-2">
            <button
              aria-label="close"
              type="button"
              class="inline-flex items-center justify-center p-2 text-black bg-white rounded-md hover:text-gray-800 hover:bg-gray-100 focus:outline-none"
              on:click={closeMenu}
            >
              <span class="sr-only">Close menu</span>
              <svg
                class="w-6 h-6"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
                aria-hidden="true"
              >
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width={3}
                  d="M6 18L18 6M6 6l12 12"
                />
              </svg>
            </button>
          </div>
        </div>
        <div class="px-2 mt-6 divide-y divide-grey-md"><slot /></div>
      </div>
    </div>
  </div>
{/if}
