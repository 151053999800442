<script lang="ts">
  import chatLoader from '$lib/chatloader/context'
  import type { CurrentUser } from '$lib/stores/auth-state'
  import { Menu, MenuButton, MenuItems, Transition } from '@rgossiaux/svelte-headlessui'
  import { createEventDispatcher } from 'svelte'
  import Item from './accountmenu/Item.svelte'
  import Avatar from './Avatar.svelte'

  let className = ''
  export { className as class }
  export let embedded = false
  export let user: CurrentUser | null = null
  $: avatarSrc =
    user.pictureURL && user.pictureURL != '' ? user.pictureURL : '/marketing/icons/default-user.svg'

  const dispatch = createEventDispatcher()
  function handleSignOut() {
    dispatch('signout')
  }

  const openIntercom = () => {
    chatLoader.loadChat({ open: true })
  }

  $: name = user?.fullName || ''
</script>

<Menu class={className}>
  <MenuButton
    class="flex items-center m-0 text-right flex-shrink-0 outline-none cursor-pointer select-none rounded-md focus:outline-none"
  >
    <Avatar size="md" src={avatarSrc} alt={user.fullName} />
  </MenuButton>
  <Transition
    class="relative z-50 block"
    enter="transition ease-out duration-100"
    enterFrom="transform opacity-0 scale-95"
    enterTo="transform opacity-100 scale-100"
    leave="transition ease-in duration-75"
    leaveFrom="transform opacity-100 scale-100"
    leaveTo="transform opacity-0 scale-95"
  >
    <MenuItems static={embedded} class="outline-none">
      <div
        class={`${
          embedded
            ? 'w-full bg-transparent pt-8'
            : 'py-2 top-2 w-64 shadow-2xl rounded-md ring-1 ring-black ring-opacity-5'
        } origin-top-right absolute right-0 text-black bg-white divide-y divide-gray-200 z-[4999]`}
      >
        <div class="px-4 py-4 text-center select-none">
          <Avatar size="lg" src={avatarSrc} alt={name} />
          <div class="mt-2 leading-4">{name}</div>
          <div class="m-0 mt-1 text-xs leading-4 text-brand-secondary">{user.email}</div>
        </div>
        <div class="py-1" role="menu" aria-orientation="vertical" aria-labelledby="options-menu">
          <Item on:click={openIntercom} icon="/marketing/icons/feedback/report-issue.svg"
            >Report an Error</Item
          >
          <Item on:click={openIntercom} icon="/marketing/icons/feedback/help-message.svg"
            >Ask a Question</Item
          >
        </div>
        <div class="pt-1">
          <Item on:click={handleSignOut} icon="/marketing/icons/navigation/logout.svg"
            >Sign Out</Item
          >
        </div>
      </div>
    </MenuItems>
  </Transition>
</Menu>
