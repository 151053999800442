<script lang="ts">
  import { MenuItem } from '@rgossiaux/svelte-headlessui'
  export let icon = ''
  export let label = ''
</script>

<MenuItem let:active on:click>
  <span
    class:active
    class="active:bg-grey-main active:text-black flex items-center flex-1 px-4 py-3 place-items-center w-full cursor-pointer bg-white focus:outline-none text-base font-normal text-gray-900 hover:bg-grey-md hover:text-black space-x-4"
  >
    <img width={16} height={16} src={icon} alt={`icon`} />
    <span class="w-full leading-4"><slot>{label}</slot></span>
  </span>
</MenuItem>
