<script lang="ts" context="module">
  const computeSizeClasses = (size: 'sm' | 'md' | 'lg' | 'xl'): [number, number, string] => {
    let width = 48
    let height = 48
    let sizeClasses = 'w-12 h-12'

    if (size === 'sm') {
      width = 32
      height = 32
      sizeClasses = 'w-8 h-8'
    } else if (size === 'md') {
      width = 40
      height = 40
      sizeClasses = 'w-11 h-11'
    } else if (size === 'lg') {
      width = 48
      height = 48
      sizeClasses = 'w-12 h-12'
    } else if (size === 'xl') {
      width = 96
      height = 96
      sizeClasses = 'w-24 h-24'
    }
    return [width, height, sizeClasses]
  }
</script>

<script lang="ts">
  import { cdnSrc } from 'src/utils/cloudflare-loader'

  export let size: 'sm' | 'md' | 'lg' | 'xl'
  const [width, height, sizeClasses] = computeSizeClasses(size)
  export let src: string
  export let alt: string
  $: computedSrc = cdnSrc(src, width)

  function handleImageLoadError() {
    computedSrc = '/marketing/icons/default-user.svg'
  }
</script>

<span class={`${sizeClasses} inline-block m-0 overflow-visible bg-white border-2 rounded-full`}>
  <img
    src={computedSrc}
    class="rounded-full"
    {alt}
    {width}
    {height}
    on:error={handleImageLoadError}
  />
</span>
