<script lang="ts">
  import { page } from '$app/stores'
  import feature from '$lib/stores/feature'
  import { createEventDispatcher } from 'svelte'
  import { fly } from 'svelte/transition'
  import FeatureLink from './FeatureLink.svelte'

  $: featureEnabled = $feature['trip-builder']
  $: hotelFeatureEnabled = $feature['hotels']

  $: bannerFeatureEnabled = $feature['banner']
  $: isBannerBlocked = $page.data.blockBanner

  export let navType: NavType

  const dispatch = createEventDispatcher()
  const handleNavClose = (event: MouseEvent) => {
    if (navType !== 'search') dispatch('close')
  }

  $: isOpen = navType === 'explore' || navType === 'trips'
</script>

{#if isOpen}
  <div
    class={`${isOpen ? 'block' : 'hidden'} ${
      bannerFeatureEnabled && !isBannerBlocked ? 'top-28' : 'top-16'
    } fixed z-50 inset-x-0 text-black sm:text-sm`}
    transition:fly|local={{ duration: 150, y: -20, opacity: 0 }}
  >
    <div class="bg-white shadow">
      <div class="px-8 mx-auto max-w-screen-2xl">
        <div class="items-start pt-6 pb-8 grid grid-cols-3 gap-x-8">
          {#key navType}
            {#if navType === 'explore'}
              <div class="grid grid-cols-4 col-span-2 gap-x-2">
                <!-- <FeatureLink
                  href={'/stays'}
                  title="Stays"
                  description="Reserve homes, hotels, hostels and more."
                  iconSrc={'/marketing/icons/navigation/accommodation.svg'}
                  featureEnabled={true}
                  on:click={handleNavClose}
                /> -->
                <FeatureLink
                  href={'/travel-restrictions/united-states-vaccinated'}
                  title="Restrictions"
                  description="Learn what's required for your trips abroad."
                  iconSrc={'/marketing/icons/navigation/restrictions.svg'}
                  featureEnabled={true}
                  on:click={handleNavClose}
                />
                <FeatureLink
                  href={'/visas'}
                  title="Visas"
                  description="Learn about and purchase entry visas."
                  iconSrc={'/marketing/icons/visa.svg'}
                  featureEnabled={true}
                  on:click={handleNavClose}
                />
                <FeatureLink
                  href={'/explore'}
                  title="Trips"
                  description="View trips created by other travelers."
                  iconSrc={'/marketing/icons/navigation/explore.svg'}
                  featureEnabled={false}
                  on:click={handleNavClose}
                />
              </div>
            {:else if navType === 'trips'}
              <div class="grid grid-cols-4 col-span-2 gap-x-2">
                <FeatureLink
                  href={'/trips/pinned'}
                  title="Pinned"
                  featureEnabled={true}
                  description="Travel restrictions for routes you have pinned."
                  iconSrc={'/marketing/icons/navigation/pin-dark.svg'}
                  on:click={handleNavClose}
                />
                <FeatureLink
                  href={'/trips'}
                  title="Your Trips"
                  description="Trips you are planning or have booked."
                  iconSrc={'/marketing/icons/navigation/trips.svg'}
                  featureEnabled={true}
                  on:click={handleNavClose}
                />
                <FeatureLink
                  href={'/trips/saved'}
                  title="Saved"
                  description="Saved trips created by other travelers."
                  iconSrc={'/marketing/icons/navigation/saved.svg'}
                  featureEnabled={false}
                  on:click={handleNavClose}
                />
              </div>
            {/if}
          {/key}
          <div class="h-full pl-16 col-span-1 border-l-1 border-grey-dk">
            <div class="grid grid-cols-1 gap-x-8 gap-y-4 pt-4">
              <div>
                <h5 class="text-base font-medium">Additional Resources</h5>
                <ul class="mt-4 space-y-2">
                  <li>
                    <a
                      href="/blog"
                      data-sveltekit-preload-data
                      class="text-sm text-gray-black hover:text-gray-700"
                    >
                      Read the Blog
                    </a>
                  </li>
                  <li>
                    <a
                      href="https://intercom.help/airheart/en/collections/2729330-airheart-help-center"
                      target="_blank"
                      class="text-sm text-gray-black hover:text-gray-700"
                    >
                      Contact Support
                    </a>
                  </li>
                  <li>
                    <a
                      href="https://airheart.notion.site/Creators-Program-Sell-Trips-on-Airheart-030a8e7a0309472da5da5b7d2abc7ff0"
                      target="_blank"
                      class="text-sm text-gray-black hover:text-gray-700"
                    >
                      Sell Your Trips
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
{/if}
