<script lang="ts">
  import feature from '$lib/stores/feature'
  import { encodeRestrictionOriginUrl } from 'src/utils/restrictions/restriction-urls'
  import { createEventDispatcher } from 'svelte'
  import PanelItem from './navigation/PanelItem.svelte'

  export let origin: string
  if (origin === null) origin = 'united-states'
  $: restrictionsUrl = encodeRestrictionOriginUrl(origin, true)
  $: tripBuilderEnabled = $feature['trip-builder']

  const dispatch = createEventDispatcher()
  function handleClose(event: CustomEvent) {
    dispatch('close')
  }
</script>

<ul class="space-y-2">
  <!-- <li>
    <PanelItem
      title={'Stays'}
      iconSrc={'/marketing/icons/navigation/accommodation.svg'}
      url={'/stays'}
      featureEnabled={true}
      on:close={handleClose}
    />
  </li> -->
  <li>
    <PanelItem
      title={'Restrictions'}
      iconSrc={'/marketing/icons/navigation/restrictions.svg'}
      url={restrictionsUrl}
      featureEnabled={true}
      on:close={handleClose}
    />
  </li>
  <li>
    <PanelItem
      title={'Visas'}
      iconSrc={'/marketing/icons/visa.svg'}
      url={'/visas'}
      featureEnabled={true}
      on:close={handleClose}
    />
  </li>
  <li>
    <PanelItem
      title={'Trips'}
      iconSrc={'/marketing/icons/navigation/explore.svg'}
      url={'/explore'}
      on:close={handleClose}
      featureEnabled={false}
    />
  </li>
</ul>
