<script lang="ts">
  import feature from '$lib/stores/feature'
  import { createEventDispatcher } from 'svelte'
  import PanelItem from './PanelItem.svelte'

  const dispatch = createEventDispatcher()
  function closeMenu(event: CustomEvent) {
    dispatch('close')
  }
</script>

<ul class="space-y-2">
  <li>
    <PanelItem
      title={'Your Trips'}
      iconSrc={'/marketing/icons/navigation/trips.svg'}
      url={'/trips'}
      on:close={closeMenu}
      featureEnabled={true}
    />
  </li>
  <li>
    <PanelItem
      title={'Pinned Routes'}
      iconSrc={'/marketing/icons/navigation/pin-dark.svg'}
      url={'/trips/pinned'}
      on:close={closeMenu}
      featureEnabled={true}
    />
  </li>
  <li>
    <PanelItem
      title={'Saved Trips'}
      iconSrc={'/marketing/icons/navigation/saved.svg'}
      url={'/trips/saved'}
      on:close={closeMenu}
      featureEnabled={false}
    />
  </li>
</ul>
