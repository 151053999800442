<script lang="ts">
  import { page } from '$app/stores'
  import { signOut } from '$lib/authentication/firebase'
  import state from '$lib/stores/auth-state'
  import modal from '$lib/stores/modal-state'
  import { Search } from '@steeze-ui/heroicons'
  import { Icon } from '@steeze-ui/svelte-icon'
  import { createEventDispatcher } from 'svelte'
  import AccountMenu from './AccountMenu.svelte'
  import ActiveLink from './ActiveLink.svelte'
  import Banner from './ui/display/Banner.svelte'
  import solution from '$lib/stores/solution'
  import { browser } from '$app/environment'

  $: isBannerBlocked = $page.data.blockBanner

  const dispatch = createEventDispatcher<{ open: NavType; close: void }>()
  let handleNavOpen = (navType: NavType) => {
    dispatch('open', navType)
  }
  const handleMouseEnterExplore = () => handleNavOpen('explore')
  const handleMouseEnterTrips = () => handleNavOpen('trips')

  export let navType: NavType = null
  export let navTypeOpen: NavType = null
  $: signedIn = $state.signedIn

  function handleJoinWaitlistClick(event: MouseEvent) {
    event.preventDefault()
    modal.open('signup', { intent: 'joinWaitlist' })
  }

  function handleShowLoginClick(event: MouseEvent) {
    event.preventDefault()
    modal.open('signup', { intent: 'signIn' })
  }

  function setClose(event: MouseEvent) {
    dispatch('close')
  }

  function handleClickOnMobile(event: MouseEvent) {
    event.preventDefault()
    dispatch('open', 'mobile')
  }

  function handleClickOnSearch(event: MouseEvent) {
    event.preventDefault()
    modal.open('search')
  }

  function handleSelectLocalization(event: MouseEvent) {
    event.preventDefault()
    modal.open('localization-select')
  }

  function handleSignOut(event: CustomEvent) {
    signOut()
  }
</script>

<nav
  class="lg:fixed shadow-sm mx-auto top-0 mb-[2px] left-0 right-0 bg-white z-40 flex flex-col items-center justify-center"
>
  {#if !isBannerBlocked && $page.data.banner}
    <Banner banner={$page.data.banner} />
  {/if}

  <div class="max-w-screen-2xl flex items-stretch justify-between h-16 px-2 lg:px-8 w-full">
    <div class="flex px-2 lg:px-0">
      <div on:mouseenter={setClose} class="flex items-center flex-shrink-0 lg:pr-6">
        <a href="/" class="flex items-center leading-none">
          <img src="/airheart-logo-full-new.svg" height={32} width={128} alt="Airheart Logo" />
        </a>
      </div>
    </div>

    <div class={'hidden lg:flex'}>
      <ActiveLink
        href="/travel-restrictions/united-states-vaccinated"
        activeClass="border-brand-primary"
        inactiveClass="border-transparent"
        active={navTypeOpen === 'explore'}
        navIsOpen={navTypeOpen !== null}
        activeWhen={/(visas|visas\/(.*)|stays|stays\/(.*)|explore|explore\/(.*)|travel-restrictions(\/+\w+)|\/travel-bans\/(.+)\/)/}
        on:click={setClose}
        on:mouseenter={handleMouseEnterExplore}
        class="flex items-center justify-center w-24 pt-2 text-sm font-medium tracking-tight text-center text-black border-b-2 px-auto"
      >
        Explore
      </ActiveLink>
      <ActiveLink
        href="/trips/pinned"
        activeClass="border-brand-primary"
        inactiveClass="border-transparent"
        active={navTypeOpen === 'trips'}
        navIsOpen={navTypeOpen !== null}
        activeWhen={/trips\/*/}
        on:click={setClose}
        on:mouseenter={handleMouseEnterTrips}
        class="flex items-center justify-center w-24 pt-2 text-sm font-medium tracking-tight text-center text-black border-b-2 px-auto"
      >
        Your Trips
      </ActiveLink>
    </div>

    <div
      on:mouseenter={setClose}
      class="flex items-center justify-end space-x-2 flex-1 w-full px-4"
    >
      <!-- {#if browser && $page?.url?.pathname?.startsWith('/stays')}
        {@const preferredCurrency = $solution?.hotelQuery?.currencyCode}
        <button
          class="flex items-center space-x-2 hover:bg-grey-md border border-transparent rounded-full px-4 py-2"
          on:click={handleSelectLocalization}
        >
          <span class="text-sm font-medium leading-6">{preferredCurrency}</span>
          <img
            class="w-4 h-4"
            src={'/marketing/icons/localization.svg'}
            alt="search icon"
            width="16"
            height="16"
          />
        </button>
      {/if} -->
      <button
        aria-label="Search"
        type="button"
        class="items-center block mx-2 text-base font-medium text-black sm:hidden whitespace-nowrap hover:text-gray-800 focus:outline-none focus:ring-transparent focus:ring-offset-0"
        on:click={handleClickOnSearch}
      >
        <span class="sr-only">Search restrictions</span>
        <img
          class="w-5 h-5"
          src={'/marketing/icons/navigation/search.svg'}
          alt="search icon"
          width="20"
          height="20"
        />
      </button>
      <div
        id="search"
        class="items-center justify-between hidden h-11 py-4 pl-3 pr-4 mx-2 space-x-2 leading-4 border rounded-full cursor-pointer hover:bg-grey-md lg:mx-0 group sm:flex border-grey-main bg-grey-main"
        on:click={handleClickOnSearch}
      >
        <Icon src={Search} class="w-5 h-5 text-gray-900" aria-hidden="true" />

        <div class="flex items-center space-x-1">
          <span class="text-sm font-medium leading-6">Search Restrictions</span>
        </div>
      </div>
    </div>

    <div class="flex items-center mx-2 lg:hidden">
      <button
        aria-label="Open Menu"
        type="button"
        class="inline-flex items-center text-base font-medium text-black whitespace-nowrap hover:text-gray-800 focus:outline-none focus:ring-transparent focus:ring-offset-0"
        on:click={handleClickOnMobile}
      >
        <span class="sr-only">Open sidebar</span>
        <span class="hidden mr-3 sm:flex">Menu</span>
        <img
          class="w-4 h-4"
          height={16}
          width={16}
          src={'/marketing/icons/navigation/menu.svg'}
          alt="menu"
        />
      </button>
    </div>
    <div class="items-center hidden space-x-4 lg:flex">
      <!-- {#if $feature['trip-builder']}
        <LinkButton size="sm" theme="dark" href={'/trips/new'}>
          Create a Trip
        </LinkButton>
      {/if} -->

      {#if signedIn}
        <AccountMenu
          class="relative flex-shrink-0"
          user={$state.currentUser}
          on:signout={handleSignOut}
          embedded={false}
        />
      {:else}
        <button
          id="waitlist"
          aria-label="Join the waitlist"
          on:click={handleJoinWaitlistClick}
          class="inline-flex items-center justify-center px-4 py-2 text-base font-medium text-white bg-black border border-transparent rounded-full shadow-sm whitespace-nowrap focus:outline-none hover:bg-gray-800"
        >
          Join the Waitlist
        </button>
        <button
          id="sign-in"
          aria-label="Sign in"
          on:click={handleShowLoginClick}
          class="text-base font-medium text-black whitespace-nowrap hover:text-gray-800 focus:outline-none"
        >
          Log in
        </button>
      {/if}
    </div>
  </div>
</nav>
