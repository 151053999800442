<script lang="ts">
  export let title: string = null
  export let description: string = null
  export let href: string
  export let featureEnabled = false
  export let iconSrc = ''
  export let external: true | null = null
</script>

<a
  {href}
  class={`group flex flex-col select-none items-start justify-start p-4 gap-y-4  rounded-2xl ${
    !featureEnabled ? 'pointer-events-none opacity-50' : 'hover:bg-grey-main'
  }`}
  on:click
  data-sveltekit-reload={external}
  disabled={!featureEnabled}
>
  {#if iconSrc != ''}
    <img src={iconSrc} alt="nav item icon" height={20} width={20} class="w-5 h-5" />
  {/if}
  <h5 class="flex items-center text-lg font-medium text-gray-900 space-x-1">
    <slot>
      <span>{title}</span>
      {#if !featureEnabled}
        <span
          class="inline-flex items-center px-2 py-0.5 rounded-full text-xs font-medium bg-grey-dk text-gray-800 group-hover:bg-warm-gray-300"
        >
          Soon
        </span>
      {/if}
    </slot>
  </h5>
  <p>{description}</p>
</a>
