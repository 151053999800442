<script lang="ts">
  import { page } from '$app/stores'
  import { signOut } from '$lib/authentication/firebase'
  import state from '$lib/stores/auth-state'
  import modal from '$lib/stores/modal-state'
  import { restrictionRoute } from '$lib/stores/restrictionRoute'
  import ExplorePanel from './ExplorePanel.svelte'
  import MoreMenu from './MoreMenu.svelte'
  import MainDropdown from './navigation/MainDropdown.svelte'
  import TripsPanel from './navigation/TripsPanel.svelte'
  import TopNavigation from './TopNavigation.svelte'

  $: bannerFeatureEnabled = $page.data.banner
  $: isBannerBlocked = $page.data.blockBanner

  let navType: NavType = null

  const handleNavOpen = (event: CustomEvent<NavType>) => {
    navType = event.detail
  }

  function setClose() {
    navType = null
  }

  function handleSignOut() {
    signOut()
  }

  function handleSignIn(event: MouseEvent) {
    event.preventDefault()
    modal.open('signup', { intent: 'signIn' })
  }

  function handleJoinWaitlistClick(event: MouseEvent) {
    event.preventDefault()
    modal.open('signup', { intent: 'joinWaitlist' })
  }

  $: isSignedIn = $state.signedIn
</script>

<div on:mouseleave={setClose}>
  <MainDropdown {navType} on:close={setClose} />

  <MoreMenu isOpen={navType === 'mobile'} on:close={setClose}>
    <nav class="grid gap-y-8">
      <h4 class="px-4 -mb-4 text-base font-medium tracking-tight">Explore</h4>
      <ExplorePanel origin={$restrictionRoute.origin} on:close={setClose} />
      <h4 class="px-4 -mb-4 text-base font-medium tracking-tight">Your Trips</h4>
      <TripsPanel on:close={setClose} />
      <!-- {#if $feature['trip-builder']}
        <Button
          as="a"
          size="sm"
          svelte:reload
          class={`block py-6 ml-4 mr-4 2xl:mr-0 font-normal capitalize rounded-full`}
          href={'/trips/new'}
        >
          Create a Trip
        </Button>
      {/if} -->
    </nav>
    <div class="px-5 mt-6 pt-6 space-y-6">
      {#if isSignedIn}
        <div class="text-center">
          <button
            aria-label="Sign out"
            class="text-base font-medium text-gray-900 focus:outline-none"
            on:click={handleSignOut}
          >
            Sign Out
          </button>
        </div>
      {:else}
        <div class="flex flex-col space-y-4 w-full justify-center items-center">
          <p class="text-base font-medium text-center text-gray-900">
            <button
              aria-label="Sign in"
              on:click={handleSignIn}
              class="ml-2 text-black focus:outline-none hover:text-gray-900"
            >
              Sign in
            </button>
          </p>

          <button
            id="waitlist"
            aria-label="Join the waitlist"
            on:click={handleJoinWaitlistClick}
            class="inline-flex w-full items-center justify-center px-4 py-2 text-base font-medium text-white bg-black border border-transparent rounded-full shadow-sm whitespace-nowrap focus:outline-none hover:bg-gray-800"
          >
            Join the Waitlist
          </button>
        </div>
      {/if}
    </div>
  </MoreMenu>

  <TopNavigation {navType} navTypeOpen={navType} on:close={setClose} on:open={handleNavOpen} />

  <div
    class={`hidden lg:block h-0 ${
      bannerFeatureEnabled && !isBannerBlocked ? 'lg:h-32' : 'lg:h-16'
    }`}
  >
    &nbsp;
  </div>
</div>
